@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Play:wght@400;700&display=swap');
@import url('https://db.onlinewebfonts.com/c/1868e326d3ee28e5395f6efa2bc037bf?family=Eina01-Regular');

html {
  font-family: Eina01-Regular;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Eina01';
  font-weight: 600;
  text-rendering: geometricPrecision;
  src: url('./fonts/Eina01-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Eina01';
  font-weight: 700;
  text-rendering: geometricPrecision;
  src: url('./fonts/Eina01-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'Eina01';
  font-weight: 600;
  text-rendering: geometricPrecision;
  src: url('./fonts/Eina01-SemiBold.ttf') format('woff');
}
